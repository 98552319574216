import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderTable from "../tables/OrderTable";
import $ from 'jquery'
import 'select2';
import Input from "../components/Input";
import SelectTag from "../components/SelectTag"
import ProductSearchForm from "../Forms/ProductSearchForm";
import CustomModal from "../components/CustomModal";
import { appendScript } from "../utility/AppendScript";
import axios from "axios";

   const JsonOrderLines = [
    {
        "SAP_CODE": "1",
        "ITEM_NAME": "",
        "WEIGHT": "",
        "UOM": "",
        "PACK": "",
        "ITEM_LENGTH": "",
        "ITEM_WIDTH": "",
        "ITEM_HEIGHT": "",
        "CBM":0,
        "CARTON_WEIGHT": "",
        "TOTAL_CARTON": "",
        "REMARK": "",
    },
    {
        "SAP_CODE": "2",
        "ITEM_NAME": "",
        "WEIGHT": "",
        "UOM": "",
        "PACK": "",
        "ITEM_LENGTH": "",
        "ITEM_WIDTH": "",
        "ITEM_HEIGHT": "",
        "CBM":0,
        "CARTON_WEIGHT": "",
        "TOTAL_CARTON": "",
        "REMARK": "",
    },
    {
        "SAP_CODE": "3",
        "ITEM_NAME": "",
        "WEIGHT": "",
        "UOM": "",
        "PACK": "",
        "ITEM_LENGTH": "",
        "ITEM_WIDTH": "",
        "ITEM_HEIGHT": "",
        "CBM":0,
        "CARTON_WEIGHT": "",
        "TOTAL_CARTON": "",
        "REMARK": "",
    },
    {
        "SAP_CODE": "4",
        "ITEM_NAME": "",
        "WEIGHT": "",
        "UOM": "",
        "PACK": "",
        "ITEM_LENGTH": "",
        "ITEM_WIDTH": "",
        "ITEM_HEIGHT": "",
        "CBM":0,
        "CARTON_WEIGHT": "",
        "TOTAL_CARTON": "",
        "REMARK": "",
    },
   ]



function AddOrder(props) {
    const [message, setMessage] = useState('')
    const [displayMessage, setDisplayMessage] = useState(true)
    const navigate = useNavigate() 

    useEffect(() => {
        if(!localStorage.getItem('sessAuth')) {
            navigate('/sign-in')
        }

        if(localStorage.getItem('role') === 'accounts') {
            navigate('/accounts')
        }

        

    },[])

    useEffect(() => {
        setMessage(localStorage.getItem('message'))
        setDisplayMessage(false)
    }, [])

    useEffect(() => {
        if(displayMessage === false) {
            localStorage.removeItem('message')
        }
    }, [displayMessage])

    const [jsonProducts, setJsonProducts] = useState({})
    const [products, setProducts] = useState(jsonProducts)
    const [orderLines, setOrderLines] = useState(JsonOrderLines)
    const [orderInfo, setOrderInfo] = useState({})
    const [customers, setCustomers] = useState({})
    const [customer, setCustomer] = useState({})

    const [carton, setCarton] = useState(0)
    const [salesTerm, setSalesTerm] = useState('')
    const [portOfDestination, setPortOfDestination] = useState('')
    const [containerSize, setContainerSize] = useState('')
    const [modeOfTransport, setModeOfTransport] = useState('')
    const [orderDate, setOrderDate] = useState('')

   


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(localStorage.getItem('baseApiUrl')+ 'ImplExpItems?sap_code=', {
              headers: {
                Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
              },
            });
            setJsonProducts(response.data.Data)
            setProducts(response.data.Data)
          } catch (error) {
            console.log('Error:', error)
          } 
        };
    
        fetchData();
    }, []);

    const [selectOptions, setSelectOptions] = useState([{"label":"Select", "value": ""}])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpCustomers?sap_code=', {
              headers: {
                Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
              },
            });
            setJsonProducts(response.data.Data)
            setCustomers(response.data.Data)
            const options = response.data.Data.map((customer) => ({
                label: customer.CUSTOMER_NAME, 
                value: customer.SAP_CODE,
            }))
            setSelectOptions([...selectOptions, ...options]);
            
            if(localStorage.getItem('role') === 'customer') {
                const selectedCustomer = response.data.Data.find((item) => item.SAP_CODE === localStorage.getItem('customerCode'));
                console.log(selectedCustomer)
                setCustomer(selectedCustomer)
                setOrderInfo({
                    ...orderInfo,
                    CUSTOMER: selectedCustomer,
                })
            }
            
            
          } catch (error) {
            console.log('Error:', error)
          } 
        };
    
        fetchData();
    }, []);

    function saveOrder (e) {
        // e.preventDefault()
        const response = axios.post(localStorage.getItem('baseApiUrl')+'ImplExpOrder', orderInfo, {
                headers: {
                    Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                },
            }
        )
        .then(response => {
          toggleConfirmOrder(false)
          localStorage.setItem('message', response.data.Data)
          setOrderLines(JsonOrderLines)
          setCustomer({})
          setPortOfDestination('')
          setContainerSize('')
          setModeOfTransport('')
          setSalesTerm('')
          setOrderDate('')
          setTotalCbm(0)
          setOrderInfo({})
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }


    const [modalData, setModalData] = useState({'products': products, 'index' : ''})

    //modal toggle state
    const [isOpen, open] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    useEffect(() => {
        setModalTitle('Search Items');
    }, [isOpen])
    

    function toggleModal() {
        open(false)
    }
 

    const searchProduct = (index) => {
        var availableProducts = []
        setModalData({'products': products, 'index' : index})
       
        availableProducts = products.filter(product => product.flag !== 'added' || product.flag === undefined);
        open(true)
    } 


    const addOrderLines = () => {
        const orderLine = {
            "SAP_CODE": "",
            "ITEM_NAME": "",
            "WEIGHT": "",
            "UOM": "",
            "PACK": "",
            "ITEM_LENGTH": "",
            "ITEM_WIDTH": "",
            "ITEM_HEIGHT": "",
            "CBM":"",
            "CARTON_WEIGHT": "",
            "TOTAL_CARTON": "",
            "REMARK": "",
            }
        setOrderLines([...orderLines,orderLine])
    }

    const updateProducts = (products) => {
        setOrderLines(products)
    }
    

    const updateOrderLine = (data)  => {
        
        const updatedOrderLines = [...orderLines]
        updatedOrderLines[data.index].SAP_CODE = data.product.SAP_CODE
        updatedOrderLines[data.index].ITEM_NAME = data.product.ITEM_NAME
        updatedOrderLines[data.index].WEIGHT = data.product.WEIGHT
        updatedOrderLines[data.index].ITEM_LENGTH = data.product.ITEM_LENGTH
        updatedOrderLines[data.index].ITEM_WIDTH = data.product.ITEM_WIDTH
        updatedOrderLines[data.index].ITEM_HEIGHT = data.product.ITEM_HEIGHT
        updatedOrderLines[data.index].UOM = data.product.UOM
        updatedOrderLines[data.index].PACK = data.product.PACK
        updatedOrderLines[data.index].CARTON_WEIGHT = data.product.CARTON_WEIGHT
        updatedOrderLines[data.index].CBM = data.product.CBM
        updatedOrderLines[data.index].REMARK = data.product.REMARK
        
        setOrderLines(updatedOrderLines)

        const updatedProducts = products.map((item) => {
            if (item.SAP_CODE === data.product.SAP_CODE) {
                return { ...item, FLAG: "added", CBM: data.product.CBM };
            }
            return item;
        });
        
        setProducts(updatedProducts)
        setOrderInfo({
            ...orderInfo,
            ITEMS : orderLines,
        })

        open(false)
    }

    const removeOrderLine = (indexToRemove) => {
        console.log(indexToRemove + "to remove order")
        const updatedOrderLines =  orderLines.filter((orderLine, index) => index !== indexToRemove)
        setOrderLines(updatedOrderLines)
    }

    const [totalCbm, setTotalCbm] = useState(0);

    props.setNavParam('addOrder')

    function updateTotalCbm (){
        let updatedCbm = 0;
        orderLines.forEach((uProduct) => {
            if (uProduct.CBM && uProduct.TOTAL_CARTON > 0) {
            updatedCbm += parseFloat(uProduct.CBM);
            }
        });
        setTotalCbm(updatedCbm);
    }

    useEffect(() => {
        setOrderInfo({
            ...orderInfo,
            CBM: totalCbm,
        })
    }, [totalCbm]);


    

    function handleOrderDateChange (e) {
        setOrderDate(e.target.value)
        setOrderInfo({
            ...orderInfo,
            ORDER_DATE: e.target.value,
        })        
    }

    function handleCustomerChange(e) {
        const selectedCustomer = customers.find((item) => item.SAP_CODE === e.target.value);

        setCustomer(selectedCustomer)
        setOrderInfo({
            ...orderInfo,
            CUSTOMER: selectedCustomer,
        })
    }

    function handleSalesTermChange(e) {
        setSalesTerm(e.target.value)
        setOrderInfo({
            ...orderInfo,
            SALES_TERM: e.target.value,
        })
    }

    function handlePortOfDestinationChange (e) {
        const { name, value } = e.target;
        setPortOfDestination(e.target.value)
        setOrderInfo({
            ...orderInfo,
            PORT_OF_DESTINATION: e.target.value,
        })        
    }
    function handleContainerSizeChange (e) {
        const { name, value } = e.target;
        setContainerSize(e.target.value)
        setOrderInfo({
            ...orderInfo,
            CONTAINER_SIZE: e.target.value,
        })        
    }

    function handleModeOfTransportChange (e) {
        setModeOfTransport(e.target.value)
        setOrderInfo({
            ...orderInfo,
            MODE_OF_TRANSPORT: e.target.value,
        })

    }


    const tableTitle = 'Customers';
    const [confirmOrder, setConfirmOrder] = useState(false)


    const [portOfDestinationStyle, setPortOfDestinationStyle] = useState({
        background: '#ffff'
    })

    const [containerSizeStyle, setContainerSizeStyle] = useState({
        background: '#ffff'
    })

    const [modeOfTransportStyle, setModeOfTransportStyle] = useState({
        background: '#ffff'
    })

    const [salesTermStyle, setSalesTermStyle] = useState({
        background: '#ffff'
    })

    const [orderDateStyle, setOrderDateStyle] = useState({
        background: '#ffff'
    })

    const toggleConfirmOrder = (e) => {
        var submitForm = false

        console.log("---------------customer----------")
        console.log(orderInfo)



        if(portOfDestination === '') {
            setPortOfDestinationStyle({background : '#ffdddd'})
        } 
        if(containerSize === '') {
            setContainerSizeStyle({background : '#ffdddd'})
        } 
        if(modeOfTransport === '') {
            setModeOfTransportStyle({background : '#ffdddd'})
        } 
        if(orderDate === '') {
            setOrderDateStyle({background : '#ffdddd'})
        }

        if(totalCbm === 0) {
            alert("Total Carton Required!")
        } 

        if(salesTerm === '') {
            setSalesTermStyle({background : '#ffdddd'})
        } 

        if(portOfDestination === '' || containerSize === '' || modeOfTransport === '' || totalCbm === 0 || salesTerm === '' || orderDate === '')
        {
            submitForm = false
        } else {
            submitForm = true
        }
        console.log(submitForm)
        console.log("customer")
        console.log("portOfDestination")
        console.log(portOfDestination)
        console.log("containerSize")
        console.log(containerSize)
        console.log("modeOfTransport")
        console.log(modeOfTransport)
        console.log("totalCbm")
        console.log(totalCbm)
        console.log(salesTerm)
        console.log(orderDate)
        if(submitForm === true) {
            confirmOrder === false? setConfirmOrder(true) : setConfirmOrder(false)
        }
        console.log(orderInfo)
    }

    const selectRef = useRef()

    useEffect(() => {
        // Initialize the select2 plugin on component mount
        $(selectRef.current).select2();
        $(selectRef.current).on('change', handleCustomerChange);

        
        // Clean up the select2 instance on component unmount
        return () => {
          $(selectRef.current).select2('destroy');
          $(selectRef.current).off('change', handleCustomerChange);
        };
    }, [customers]);

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <section className="card">
                        <header className="card-header">
                            <div className="card-actions">
                            </div>
                            <p className="text-success">{message}</p>
                        </header>
                        <div className="card-body">
                            {
                            (confirmOrder === false) &&
                                <form>
                                <div className="invoice">
                                    <header className="clearfix">
                                        
                                        

                                        <div className="row">
                                                {(localStorage.getItem('role') !== 'customer') &&
                                                <div className="col-lg-3">
                                                    <div className="form-group row mt-0 ml-1">
                                                        <label className="col-form-label" for="formGroupExampleInput">Customer</label>
                                                        <select ref={selectRef} className="form-control form-contol-sm" onChange={handleCustomerChange} value={customer.SAP_CODE} required>
                                                            {
                                                                selectOptions.map((option, index) => {
                                                                    return (
                                                                        <option value={option.value} key={index}>{option.label}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                }
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group row mt-0 ml-1">
                                                    <label className="col-form-label" for="formGroupExampleInput">Destination Port</label>
                                                    <input type="text" className="form-control form-control-sm" 
                                                        name="port_of_destination" id="inputDefault" required
                                                        placeholder="Port of Destination"
                                                        value={portOfDestination}
                                                        onChange={handlePortOfDestinationChange}
                                                        style={portOfDestinationStyle}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="col-form-label" for="formGroupExampleInput">Container</label>
                                                <select className="form-control form-control-sm" required
                                                name="container_size" onChange={handleContainerSizeChange} style={containerSizeStyle}
                                                value={containerSize}>
                                                    <option value={""}>Select</option>
                                                    <option value={'40 FT Container'}>40 FT Container</option>
                                                    <option value={'20 FT Container'}>20 FT Container</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="col-form-label" for="formGroupExampleInput">Mode of Shipment</label>
                                                <select className="form-control form-control-sm" required
                                                name="mode_of_transport" onChange={handleModeOfTransportChange} style={modeOfTransportStyle}
                                                value={modeOfTransport}>
                                                    <option value={""}>Select</option>
                                                    <option value={'BY SEA'}>BY SEA</option>
                                                    <option value={'BY LAND'}>BY ROAD</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* first row ends here */}

                                        <div className="row mb-3">
                                            <div className="col-lg-3">
                                                <label className="col-form-label" for="formGroupExampleInput">Sales Term</label>
                                                <select className="form-control form-control-sm" 
                                                name="sales_term" onChange={handleSalesTermChange} required style={salesTermStyle}
                                                value={salesTerm}>
                                                    <option value="">Select</option>
                                                    <option value='FOB'>FOB</option>
                                                    <option value='CFR'>CFR</option>
                                                    <option value='CIF'>CIF</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="col-form-label" for="formGroupExampleInput">Order Date</label>
                                                <input type="date" className="form-control form-control-sm"  style={orderDateStyle}
                                                    name="order_date" id="inputDefault" 
                                                    value={orderDate}
                                                    onChange={handleOrderDateChange} required
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="text-lg-left pt-3"></label>
                                                <p><b>CBM: </b><b>{totalCbm}</b></p>
                                                <input type="hidden" value={totalCbm} name="cbm" required/>
                                            </div>
                                        </div>
                                    </header>
                                    <OrderTable 
                                        products={orderLines} 
                                        updateProducts={updateProducts}
                                        searchProduct={searchProduct} 
                                        removeOrderLine={removeOrderLine}
                                        updateTotalCbm={updateTotalCbm}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Link className='btn btn-primary' onClick={addOrderLines}>+ Add Lines</Link>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                        <button className='btn btn-success' onClick={toggleConfirmOrder}>Continue</button>
                                    </div>
                                </div>
                            </form>
                            }
                            
                            {
                                (confirmOrder === true) &&
                            
                                <form onSubmit={saveOrder}>
                                    <div className="invoice">
                                        <header className="clearfix">
                                            <div className="row">
                                                <div className="col-xl-auto mr-xl-5 pr-xl-5 mb-4 mb-xl-0">
                                                    {/* <h3 className="text-color-dark font-weight-bold text-4 line-height-1 mt-0 mb-3">BILLING</h3> */}
                                                    <table border={0}>
                                                        <tr>
                                                            <td>Customer</td>
                                                            <td className="pl-3">: {customer.CUSTOMER_NAME}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>City</td>
                                                            <td className="pl-3">: {customer.CITY}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Street</td>
                                                            <td className="pl-3">: {customer.STREET}</td>
                                                        </tr>
                                                    </table>
                                                    {/* <ul className="list list-unstyled list-item-bottom-space-0">
                                                        <li><label>Customer:</label>{customer.CUSTOMER_NAME}</li>
                                                        <li>{customer.CITY}</li>
                                                        <li>{customer.STREET}</li>
                                                    </ul> */}
                                                    {/* <strong className="d-block text-color-dark">Email address:</strong>
                                                    <a href="mailto:johndoe@domain.com">johndoe@domain.com</a>
                                                    <strong className="d-block text-color-dark mt-3">Phone:</strong>
                                                    <a href="tel:+5551234" className="text-color-dark">555-1234</a> */}
                                                </div>
                                                <div className="col-xl-auto pl-xl-5 mb-3">
                                                    {/* <h3 className="font-weight-bold text-color-dark text-4 line-height-1 mt-0 mb-3">SHIPPING</h3> */}
                                                    
                                                    <table border={0}>
                                                        <tr>
                                                            <td>Container</td>
                                                            <td className="pl-3">: {orderInfo.CONTAINER_SIZE}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Destination Port</td>
                                                            <td className="pl-3">: {orderInfo.PORT_OF_DESTINATION}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CBM</td>
                                                            <td className="pl-3">: {orderInfo.CBM}</td>
                                                        </tr>
                                                    </table>
                                                    {/* <strong className="d-block text-color-dark">Email address:</strong>
                                                    <a href="mailto:johndoe@domain.com">johndoe@domain.com</a>
                                                    <strong className="d-block text-color-dark mt-3">Phone:</strong>
                                                    <a href="tel:+5551234" className="text-color-dark">555-1234</a> */}
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row mt-0">
                                                        <label className="col-lg-3 control-label text-lg-left pt-2" for="inputDefault"><b>CBM</b></label>
                                                        <div className="col-lg-6">
                                                            <p className="text-lg-left pt-2">{totalCbm}</p>
                                                            <input type="hidden" value={totalCbm} name="cbm" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 text-right mb-3">
                                                    <address className="ib mr-5">
                                                        ABC Ltd, 123 Porto Street, New York, USA
                                                    </address>
                                                </div>
                                            </div> */}
                                        </header>
                                        <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Item</th>
                                                    <th>Weight</th>
                                                    <th>UoM</th>
                                                    <th>Pcs/Carton</th>
                                                    <th>Dimension</th>
                                                    <th>CBM</th>
                                                    <th width="100px">Total Carton</th>
                                                    <th>Weight</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderInfo.ITEMS.map((product, index) => {
                                                        return (
                                                            product.TOTAL_CARTON &&
                                                            <tr key={index + product.SAP_CODE}>
                                                                <td>{index + 1}</td>
                                                                <td>{product.ITEM_NAME}</td>
                                                                <td>{product.WEIGHT}</td>
                                                                <td>{product.UOM}</td>
                                                                <td>{product.PACK}</td>
                                                                <td>{(product.ITEM_LENGTH !== "") ? product.ITEM_LENGTH + "X" + product.ITEM_WIDTH + "X" + product.ITEM_HEIGHT : ""}</td>
                                                                <td>{product.CBM}</td>
                                                                <td className="p-0 m-0">
                                                                    {product.TOTAL_CARTON}
                                                                </td>
                                                                <td>{product.WEIGHT}</td>
                                                                <td>{product.REMARK}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                                       
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <button className='btn btn-warning' onClick={toggleConfirmOrder}>Edit Order</button>
                                        </div>
                                        <div className="col-lg-6 text-right">
                                            <button className='btn btn-success' >Confirm</button>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </section>
                </div>
                <CustomModal 
                    toggleModal={toggleModal} 
                    openStatus={isOpen}
                    title={modalTitle}
                    modalForm={ProductSearchForm}
                    data={modalData}
                    callback={updateOrderLine}
                />

            </div>
        </>
    ) 
}

export default AddOrder;