import { Link } from "react-router-dom";
import CustomerTable from "../tables/CustomerTable";
import { useState, useEffect } from "react";
import axios from "axios";



function CustomerList(props) {

    const [customers, setCustomers] = useState([{}])

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpCustomers?sap_code=', {
            headers: {
                Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
            },
            });
            setCustomers(response.data.Data)
        } catch (error) {
            console.log('Error:', error)
        } 
        };

        fetchData();
    }, []);

    props.setNavParam('customerList')

    useEffect(() => {
        console.log(customers)
    },[customers])

    return (
        <>
            <div class="row">
                <div class="col-lg-12">
                    <section class="card">
                        <header class="card-header">
                            <div class="card-actions">
                                {/* <a href="#" class="card-action card-action-toggle" data-card-toggle></a>
                                <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a> */}
                            </div>
            
                            {/* <Link className='btn btn-primary' to='/customer'>+ Add Customer</Link> */}
                        </header>
                        <div class="card-body">
                            <CustomerTable customers={customers} />
                        </div>
                    </section>
                </div>
            </div>
        </>
    ) 
}

export default CustomerList;