import { useState, useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import PendingOrderTable from "../tables/PendingOrderTable";
import PastOrderTable from "../tables/PastOrderTable";
import axios from "axios";


function OrderList(props) {
  const [orders, setOrders] = useState([])
  const [ordersPending, setOrdersPending] = useState([])

  const navigate = useNavigate()  
  useEffect(() => {
    if(!localStorage.getItem('sessAuth')) {
      navigate('/sign-in')
    }

    

  },[])

  useEffect(() => {
    console.log(orders)
  }, [orders])

  useEffect(() => {
    if(orders.length > 0) {
      const filteredDataPending = orders.filter(order => order.STATUS === 0)
      setOrdersPending(filteredDataPending)
    }

  }, [orders])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpOrder?order_id=', {
          headers: {
            Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
          },
        });
        console.log(response.data.Data) 
        if(localStorage.getItem('role') !== 'customer') {
          setOrders(response.data.Data)
        }

        if(response.data.Data.length > 0 && localStorage.getItem('role') === 'customer') {
          const filteredOrders = response.data.Data.filter(order => order.CUSTOMER_ID === localStorage.getItem('customerCode'))
          setOrders(filteredOrders)
        }

      } catch (error) {
        console.log('Error:', error)
      } 
    };
    fetchData();
  }, []);

  const removeOrder = (orderIdToRemove) => {
    const updatedOrders = ordersPending.filter((order) => order.ORDER_ID !== orderIdToRemove);
    setOrdersPending(updatedOrders);
  };

  const removePastOrder = (orderIdToRemove) => {
    // const updatedOrders = ordersPast.filter((order) => order.ORDER_ID !== orderIdToRemove);
    // setOrdersPast(updatedOrders);
  };



  
  props.setNavParam('orderList')

  return (
      <>
        <div className="row">
            <div className="col">
                <div class="inner-toolbar clearfix">
                    <ul>
                        <li class="right">
                        {(localStorage.getItem('role') === 'administrator') &&
                            <ul class="nav nav-pills nav-pills-primary">
                                <li class="nav-item">
                                    <label>Type</label>
                                </li>
                                
                                <li class="nav-item">
                                    <a class="nav-link active" href="#pending-orders" data-toggle="tab">Pending Orders</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#approved-orders" data-toggle="tab">Order History</a>
                                </li>
                            </ul>
                        }
                        {(localStorage.getItem('role') === 'customer') &&
                            <ul class="nav nav-pills nav-pills-primary">
                                <li class="nav-item">
                                    <a class="nav-link active" href="#approved-orders" data-toggle="tab">Order History</a>
                                </li>
                            </ul>
                        }
                        </li>
                    </ul>
                </div>

                <section class="card">
                    <div class="card-body tab-content">
                        {(localStorage.getItem('role') === 'administrator') &&
                        <>
                          <div id="pending-orders" class="tab-pane active">
                              <PendingOrderTable orders={ordersPending} removeOrder={removeOrder}/>
                          </div>
                          <div id="approved-orders" class="tab-pane">
                              {/* <EventTable events={jsonOrdersPast} heads={heads}/> */}
                              <PastOrderTable orders={orders} removeOrder={removePastOrder}/>
                          </div>
                        </>
                        }
                        {(localStorage.getItem('role') === 'customer') &&
                        <div id="approved-orders" class="tab-pane active">
                            {/* <EventTable events={jsonOrdersPast} heads={heads}/> */}
                            <PastOrderTable orders={orders} removeOrder={removePastOrder}/>
                        </div>
                        }
                    </div>
                </section>
            </div>
          </div>
      </>
  )
}


export default OrderList;