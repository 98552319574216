import { useEffect, useState } from "react"
import Input from "../components/Input";


function ProductSearchForm (props) {
    const [userInput, setUserInput] = useState('');
    const [filteredData, setFilteredData] = useState(props.data.products);
    const [displayedData, setDisplayedData] = useState('');

    useEffect(() => {
        if(userInput !== '') {
            setDisplayedData(filteredData.slice(0, 5))
        }
    }, [filteredData])

    const handleFilterChange = (e) => {
        const value = e.target.value.toLowerCase();
        setUserInput(value);
        const filtered = props.data.products.filter((item, index) =>
            item.ITEM_NAME.toLowerCase().includes(value)
        );
        setFilteredData(filtered);
    }


    return (
        <>
            <form className="form-horizontal form-bordered" method="get">
                <div className="row form-group">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Search Item</label>
                        </div>
                        <Input
                            className="form-control-sm"
                            onChange={handleFilterChange}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12">
                        <label className="col-form-label" for="formGroupExampleInput">Select item to add in your list</label>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Pcs/Carton</th>
                                    <th scope="col">Weight</th>
                                    <th scope="col">CBM</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (displayedData !== '' ) && displayedData.map((product, index) => {
                                        var cbm = (product.ITEM_LENGTH * product.ITEM_WIDTH * product.ITEM_HEIGHT) / 1000000000
                                        product.CBM = cbm.toFixed(3)
                                        
                                        const handleAddProductToOrderLines = () => {
                                            const data = {'product' : product, 'index': props.data.index, 'productIndex' : index}
                                            props.callback(data)
                                        }
                                        
                                        return (
                                            
                                            <tr key={index}>
                                                <th scope="row" >{index + 1}</th>
                                                <td>{product.ITEM_NAME}</td>
                                                <td>{product.PACK}</td>
                                                <td>{product.WEIGHT}</td>
                                                <td>{((cbm)/1000000000).toFixed(5)}</td>
                                                <td>{product.remark}</td>
                                                <td className="color-success">
                                                    {product.FLAG ? <i className="fa fa-check" style={{"color":"green"}}></i> : <a href="#" onClick={handleAddProductToOrderLines}>Add</a>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                
                            </tbody>
                            </table>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12 text-lg-right">
                        <input className="btn btn-default" type="button" onClick={props.closeModal} value={'Close'}/>
                    </div>
                </div>
                
            </form>
        </>
    )
}



export default ProductSearchForm