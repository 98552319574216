import { useState } from "react";
import OrderRow from "../components/OrderRow";

// const inlineSearch = {
//     "position": "absolute",
//     "z-index": "2",
//     "display": "block",
//     "width": "1.375rem",
//     "height": "1.375rem",
//     "line-height": "1.375rem",
//     "text-align": "center",
//     "pointer-events": "none",
//     "color": "#aaa",
// }

// const paddingLeft = {
//     "padding-left": "1.375rem"
// }



function OrderTable (props) {
    
    
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Weight</th>
                        <th>UoM</th>
                        <th>Pcs/Carton</th>
                        <th>Dimension</th>
                        <th>CBM</th>
                        <th width="100px">Total Carton</th>
                        <th>Weight</th>
                        <th>Remarks</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.products.map((product, index) => {
                            const handleSearchProduct = () => {
                                props.searchProduct(index)
                            }

                            const removeOrderLine = () => {
                                props.removeOrderLine(index)
                                console.log(index)
                            }

                            return (
                                <>
                                    <OrderRow 
                                        product={product}
                                        products={props.products}
                                        index={index} 
                                        handleSearchProduct={handleSearchProduct}
                                        removeOrderLine={removeOrderLine}
                                        updateProducts={props.updateProducts}
                                        updateTotalCbm={props.updateTotalCbm}
                                        key={index}
                                    />
                                </>
                              )
                        })
                    }
                </tbody>
            </table>
            
        </>
    ) 
}

export default OrderTable;