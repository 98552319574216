import AddProductForm from "../Forms/AddProductForm";
import { appendScript } from "../utility/AppendScript";

const categories = [
    { value: 'biscuit', label: 'Biscuit' },
    { value: 'bakery-biscuit', label: 'Bakery-biscuit' },
    { value: 'noodles', label: 'Noodles' },
    { value: 'chips', label: 'Chips' },
    { value: 'cake', label: 'Cake' },
    { value: 'others', label: 'Others' },
]


function AddEvent(props) {
    appendScript('js/theme.js');
    appendScript('js/theme.init.js');

    props.setNavParam('addProduct')

    return (
        <>
            <div class="row">
                <div class="col">
                    <div className="col-lg-9">
                        <section class="card">
                            <header class="card-header">
                            <div class="card-actions">
                                {/* <a href="{}" class="" data-card-toggle><i class="fas fa-plus"></i></a> */}
                            </div>
                            <h2 class="card-title">Add Product</h2>
                            </header>
                            <div class="card-body">
                                <AddProductForm categories={categories}/>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            
        </>
    )
}


export default AddEvent;