import { useState, useEffect } from "react"

const inlineSearch = {
    "position": "absolute",
    "zIndex": "2",
    "display": "block",
    "width": "1.375rem",
    "height": "1.375rem",
    "lineHeight": "1.375rem",
    "textAlign": "center",
    "pointerEvents": "none",
    "color": "#aaa",
    "right": "0"
}

const paddingLeft = {
    "paddingRight": "1.375rem"
}

const relativePosition = {
    "position" : "relative"
}




function PastApprovalAccountsTable (props) {
    
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order No</th>
                        <th>Order Date</th>
                        <th>Customer</th>
                        <th>Country</th>
                        <th>Status</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.orders.map((order, index) => {
                            const handleOrderDetail = () => {
                                props.getOrderDetail(order)
                            }

                            return (
                                <>
                                    <tr key={index + order.order_no}>
                                        <td>{index + 1}</td>
                                        <td>{order.order_no}</td>
                                        <td>{order.order_date}</td>
                                        <td>{order.company}</td>
                                        <td>{order.country}</td>
                                        <td>{order.status}</td>
                                        <td className="text-center"><a className="text-primary text-center font-weight-bold" href="#" onClick={handleOrderDetail}>detail</a></td>
                                    </tr>
                                </>
                              )
                        })
                    }
                </tbody>
            </table>
            
        </>
    ) 
}

export default PastApprovalAccountsTable;