function ProductTable (props) {
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>SAP ID</th>
                        <th>Name</th>
                        <th>Weight</th>
                        <th>UoM</th>
                        <th>PACK</th>
                        <th>Dimension</th>
                        <th>Weight</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.products.map((product, index) => {

                            const handleProductView = () => {
                                props.viewProductDetail(product)
                            }

                            return(
                                <tr key={index}>
                                    <td nowrap>{index + 1 }</td>
                                    <td nowrap>{product.sap_code}</td>
                                    <td nowrap>{product.name}</td>
                                    <td nowrap>{product.weight}</td>
                                    <td nowrap>{product.uom}</td>
                                    <td nowrap>{product.pack}</td>
                                    <td nowrap>{product.length + "X" + product.width + "X" + product.height}</td>
                                    <td nowrap>{product.carton_weight}</td>
                                    <td nowrap>{product.remark}</td>
                                    <td>
                                        <a onClick={handleProductView} href="#">detail</a>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            
        </>
    ) 
}

export default ProductTable;