import { useState, useEffect } from "react"
const inlineSearch = {
    "position": "absolute",
    "zIndex": "2",
    "display": "block",
    "width": "1.375rem",
    "height": "1.375rem",
    "lineHeight": "1.375rem",
    "textAlign": "center",
    "pointerEvents": "none",
    "color": "#aaa",
    "right": "0"
}

const paddingLeft = {
    "paddingRight": "1.375rem"
}

const relativePosition = {
    "position" : "relative"
}



function OrderRow ({product, products, index, handleSearchProduct, updateProducts, removeOrderLine, updateTotalCbm}) {
    const [cbm, setCbm] = useState(product.CBM || 0)
    const [total_carton, setTotalCarton] = useState(product.TOTAL_CARTON)
    const [updatedProducts, setUpdatedProduct] = useState([...products])
    

    useEffect(() => {
        if(product.ITEM_HEIGHT) {
            const updatedCbm = (product.ITEM_LENGTH * product.ITEM_HEIGHT * product.ITEM_WIDTH)/1000000000 * product.TOTAL_CARTON
            setCbm(updatedCbm.toFixed(3))
        }else{
            setCbm('')
        }
    }, [total_carton])

    useEffect(() => {
        updatedProducts[index].CBM = cbm
        updateProducts(updatedProducts)
        updateTotalCbm()
        console.log("cbm ---------")
        console.log(cbm)
        console.log(products)
    }, [cbm])

    const handleUpdateProduct = (e) => {
        const value = e.target.value
        setTotalCarton(value)
        updatedProducts[index].TOTAL_CARTON = value
    }
    
    return (
        <>
            <tr key={index + product.SAP_CODE}>
                <td>{index + 1}</td>
                <td className="p-0 m-0">
                <div className="form-group has-search" style={relativePosition}>
                    <span className="fa fa-search form-control-feedback" style={inlineSearch}></span>
                    <input type="text" className="form-control form-control-sm"  style={paddingLeft} onClick={handleSearchProduct} value={product.ITEM_NAME} readOnly={true}/>
                </div>
                </td>
                <td>{product.WEIGHT}</td>
                <td>{product.UOM}</td>
                <td>{product.PACK}</td>
                <td>{(product.ITEM_LENGTH !== "") ? product.ITEM_LENGTH + "X" + product.ITEM_WIDTH + "X" + product.ITEM_HEIGHT : ""}</td>
                <td>{cbm}</td>
                <td className="p-0 m-0">
                <input
                    type="number"
                    className="form-control form-control-sm"
                    name="total_carton[]"
                    min="0"
                    onChange={handleUpdateProduct}
                    value={total_carton}
                />
                </td>
                <td>{product.WEIGHT}</td>
                <td>{product.REMARK}</td>
                <td className="text-center"><a className="text-danger text-center font-weight-bold" href="#" onClick={removeOrderLine}>X</a></td>
            </tr>
        </>
    )
}

export default OrderRow