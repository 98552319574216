const SalesContractMailBody = (props) => {
    return (
        <>
            <table class="table table-bordered">
                <tr>
                    <td colspan="2">SALES CONTRACT</td>

                </tr>
                <tr>
                    <td>S.C NO: {props.order.SALES_CONTRACT_NO}, DATE: {props.order.ORDER_DATE}</td>
                    <td>
                        COUNTRY OF ORIGIN: BANGLADESH
                        <p>SALES TERM : {props.order.SALES_TERM}, {props.order.DESTINATION_PORT}, {props.order.DESTINATION_PORT}</p>
                    </td>
                    <tr>
                        <td>EXPORTER</td>
                        <td>
                            IMPORTER
                        </td>
                    </tr>
                    <tr>
                        <td>BENEFICIARY'S BANK:
                            <p><strong>BANK ASIA LTD.</strong></p>
                            <address>
                                CORPORATE BRANCH,NOOR TOWER(1st FLOOR)
                                110 BIR UTTAM CR DUTTA ROAD
                                OD ACCOUNT NO. 00233011222
                                SWIFT: BALBBDDH002
                            </address>
                        </td>
                        <td>
                            <p>LOADING PORT: DHAKA ICD/ CHITTAGONG, BANGLADESH</p>
                            <p>PORT OF DISCHARGE: {props.order.DESTINATION_PORT}, USA</p>
                            <p>FINAL DESTINATION: USA</p>
                            <p>FREIGHT: {props.order.FREIGHT}</p>
                            <p>INSURANCE: {props.order.INSURANCE}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                        <table  border='1' cellpadding="0" cellspacing="0" width="100%">
                            <tbody>
                                <tr>
                                    <th>
                                        SL NO.
                                    </th>
                                    <th>
                                        DESCRIPTION OF GOODS
                                    </th>
                                    <th>
                                        PACK SIZE
                                    </th>
                                    <th>
                                        H.S. CODE
                                    </th>
                                    <th>
                                        PCS / CTN
                                    </th>
                                    <th>
                                        TOTAL CARTON
                                    </th>
                                    <th>
                                        FOB VALUE US$
                                        / PCS
                                    </th>
                                    <th>
                                        FOB VALUE US$
                                        / CTN
                                    </th>
                                    <th>
                                        TOTAL FOB VALUE IN US$
                                    </th>
                                </tr>
                                {
                                    props.order.ITEMS.map((item, index) => {
                                        return(
                                            <tr key={item.ITEM_ID + index} >
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {item.ITEM_NAME}
                                                </td>
                                                <td>
                                                    {item.WEIGHT+" gm"}
                                                </td>
                                                <td>
                                                    
                                                </td>
                                                <td>
                                                    {item.PACK}
                                                </td>
                                                <td>
                                                    {item.TOTAL_CARTON}
                                                </td>
                                                <td>
                                                    {(item.PRICE / item.PACK).toFixed(2)}
                                                </td>
                                                <td>
                                                    {item.PRICE}
                                                </td>
                                                <td>
                                                    {item.PRICE * item.TOTAL_CARTON}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            </table>
                        </td>
                    </tr>

                </tr>
            </table>
        </>
    )
}


export default SalesContractMailBody
