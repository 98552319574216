function UserTable (props) {
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Customer Code</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.users.map((user, index) => {
                            return(
                                <tr key={index + user.USER_ID}>
                                    <td>{index + 1 }</td>
                                    <td>{user.USER_ID}</td>
                                    <td>{user.NAME}</td>
                                    <td>{user.ROLE}</td>
                                    <td>{user.CUSTOMER_CODE}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            
        </>
    ) 
}

export default UserTable;