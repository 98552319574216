import SelectTag from "../components/SelectTag";
import Input from "../components/Input";
import { appendScript } from "../utility/AppendScript";
import { useState, useEffect } from "react";
import axios from "axios";


function OrderHistoryForm (props) {
    const [order, setOrder] = useState(props.data.order)
    const [orderDetail, setOrderDetail] = useState(props.data.order.ITEMS)
    const [isConfirmUpdate, confirmUpdate] = useState(false)


    appendScript('js/theme.js');
    appendScript('js/theme.init.js');

    const handleOnSubmit = (e) => {
        e.preventDefault()
        setOrder((prevOrder) => {
            confirmUpdate(true)
            return {...prevOrder, STATUS: 1}
        })
        order.ITEMS = orderDetail
        props.closeModal()
    }

    useEffect(() => {
        console.log(orderDetail)
        console.log(order)
        if(isConfirmUpdate) {
            const response = axios.put('https://localhost:44356/Api/ImplExpOrder', order, {
                    headers: {
                        Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                    },
                }
            )
            .then(response => {
            console.log(response.data.Data)
            confirmUpdate(false)
            //   alert(response.data.Data)
                props.callback(order)
            })
            .catch(error => {
            console.error('API error:', error);
            });
        }
    }, [isConfirmUpdate])

    return (
        <>
            <form className="form-horizontal form-bordered" method="post" onSubmit={handleOnSubmit}>
                <div className="row mb-1">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Order No</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.ORDER_ID}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Order Date</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.ORDER_DATE}
                                isReadOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Customer</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.CUSTOMER_NAME}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Customer Code</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.CUSTOMER_ID}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Destination Port</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.DESTINATION_PORT}
                                isReadOnly={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Mode of Shipment</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={order.MODE_OF_TRANSPORT}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                

                <div className="form-group row">
                    <div className="col-lg-12">
                        <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item Code</th>
                                    <th>Item</th>
                                    <th>Pcs/Carton</th>
                                    <th>Weight</th>
                                    <th width="100px">Total Carton</th>
                                    <th width="100px">FOB Value US$/CTN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order.ITEMS.map((item, index) => {
                                        const updatedOrderDetail = orderDetail
                                        const handleOnChange = (e) => {
                                            updatedOrderDetail[index].PRICE = e.target.value
                                            console.log(updatedOrderDetail)
                                            setOrderDetail(updatedOrderDetail)
                                        }
                                        return (

                                            
                                            <tr key={item.ITEM_ID+index}>
                                                <td>{index+1}</td>
                                                <td>{item.ITEM_ID}</td>
                                                <td>{item.ITEM_NAME}</td>
                                                <td>{item.PACK}</td>
                                                <td>{item.WEIGHT + " gm"}</td>
                                                <td width="100px">{item.TOTAL_CARTON}</td>
                                                <td width="100px">
                                                    <label>{item.PRICE || item.PRICE > 0 ? item.PRICE : 'Not Updated'}</label>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12 text-lg-right">
                        <input className="btn btn-default" type="button" onClick={props.closeModal} value={'Close'}/>
                    </div>
                </div>
                
            </form>
        </>
    )
}


export default OrderHistoryForm;