import { useState, useEffect } from "react"
import CustomModal from "../components/CustomModal"
import OrderDetailForm from "../Forms/OrderDetailForm"
import SalesContractForm from "../Forms/SalesContractForm"

const inlineSearch = {
    "position": "absolute",
    "zIndex": "2",
    "display": "block",
    "width": "1.375rem",
    "height": "1.375rem",
    "lineHeight": "1.375rem",
    "textAlign": "center",
    "pointerEvents": "none",
    "color": "#aaa",
    "right": "0"
}

const paddingLeft = {
    "paddingRight": "1.375rem"
}

const relativePosition = {
    "position" : "relative"
}




function PendingSalesContractTable (props) {

    
    const [modalData, setModalData] = useState({})
    //modal toggle state
    const [isOpen, open] = useState(false);
    const [modalTitle, setModalTitle] = useState('Order Detail');

    useEffect(() => {
        setModalTitle('Search Items');
    }, [isOpen])

    function modalCallback (order) {
        props.removeOrder(order.ORDER_ID)
    }

    function toggleModal() {
        open(false)
    }

    function getOrderDetail (order) {
        setModalData({'order': order,})
        open(true)
    }

    
    
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order No</th>
                        <th>Order Date</th>
                        <th>Customer</th>
                        <th>City</th>
                        <th>Dst. Port</th>
                        <th>Container</th>
                        <th>CBM</th>
                        <th className="text-center">View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.orders.map((order, index) => {
                            const handleOrderDetail = () => {
                                getOrderDetail(order)
                            }

                            return (
                                <>
                                    <tr key={index + order.ORDER_ID}>
                                        <td>{index + 1}</td>
                                        <td>{order.ORDER_ID.toString().padStart(6, '0')}</td>
                                        <td>{order.ORDER_DATE}</td>
                                        <td>{order.CUSTOMER_NAME}</td>
                                        <td>{order.CITY}</td>
                                        <td>{order.DESTINATION_PORT}</td>
                                        <td>{order.CONTAINER_SIZE}</td>
                                        <td>{order.CBM}</td>
                                        <td className="text-center"><a className="text-primary text-center font-weight-bold" href="#" onClick={handleOrderDetail}><i class="fa fa-search" aria-hidden="true"></i></a></td>
                                    </tr>
                                </>
                              )
                        })
                    }
                </tbody>
            </table>
            <CustomModal 
                toggleModal={toggleModal} 
                openStatus={isOpen}
                title={modalTitle}
                modalForm={SalesContractForm}
                data={modalData}
                callback={modalCallback}
            />
        </>
    ) 
}

export default PendingSalesContractTable;