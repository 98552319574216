import React, { useEffect } from "react";

import Modal from 'react-modal';

const customStyles = {
  overlay: {
    position: 'fixed',
    zIndex: 1020,
    top: -200,
    left: 0,
    width: '100vw',
    height: '130vh',
    background: 'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    background: 'white',
    width: '55rem',
    maxWidth: 'calc(100vw - 2rem)',
    maxHeight: 'calc(100vh + 198rem)',
    overflowY: 'auto',
    position: 'relative',
    border: 'none',
    // border: '1px solid #ccc',
    // borderRadius: '0.3rem',
  },
};

const CustomModal = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  
  useEffect(() => {
    if(props.openStatus === true) {
      setIsOpen(true);
    }else {
      setIsOpen(false);
    }
  }, [props.openStatus])

 

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    props.toggleModal()
  }

    return (
        <>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          // contentLabel="Example Modal"
        >
          {/* <div className="modal-block modal-block-primary" >
            <props.modalForm data={props.data}/>
          </div> */}

          <section className="card">
              <header className="card-header">
                  <h2 className="card-title">{props?.title}</h2>
              </header>
              <div className="card-body">
                  <props.modalForm data={props.data} closeModal={closeModal} callback={props.callback}/>
              </div>
          </section>
        </Modal>
      </>
    )
  };
  
  export default CustomModal;