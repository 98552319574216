import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ProductTable from "../tables/ProductTable";
import CustomModal from "../components/CustomModal";
import AddProductForm from "../Forms/AddProductForm";

const products = [
    {
     "sap_code": "1400000221",
     "name": "IFAD Glucose  Biscuit",
     "weight": 30,
     "uom": "gm",
     "pack": 288,
     "length": 453,
     "width": 303,
     "height": 443,
     "carton_weight": 1250,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000551,
     "name": "IFAD Orange  Biscuit",
     "weight": 60,
     "uom": "gm",
     "pack": 144,
     "length": 448,
     "width": 283,
     "height": 353,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": "1400000129",
     "name": "IFAD Butter Delight  Biscuit",
     "weight": 70,
     "uom": "gm",
     "pack": 144,
     "length": 413,
     "width": 303,
     "height": 413,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000475,
     "name": "IFAD Butter Delight Family  Biscuit",
     "weight": 220,
     "uom": "gm",
     "pack": 24,
     "length": 398,
     "width": 353,
     "height": 243,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000476,
     "name": "IFAD Choco Delight Family  Biscuit",
     "weight": 220,
     "uom": "gm",
     "pack": 12,
     "length": 393,
     "width": 293,
     "height": 183,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000386,
     "name": "IFAD Choco Star Biscuit",
     "weight": 45,
     "uom": "gm",
     "pack": 144,
     "length": 498,
     "width": 283,
     "height": 353,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000401,
     "name": "IFAD Patates  Biscuit- Potata Flavour",
     "weight": 75,
     "uom": "gm",
     "pack": 144,
     "length": 413,
     "width": 303,
     "height": 413,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000400,
     "name": "IFAD Jeera  Biscuit",
     "weight": 70,
     "uom": "gm",
     "pack": 144,
     "length": 413,
     "width": 303,
     "height": 413,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000400,
     "name": "IFAD Biscoff  Biscuit",
     "weight": 60,
     "uom": "gm",
     "pack": 144,
     "length": 440,
     "width": 320,
     "height": 300,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000400,
     "name": "IFAD Pizza  Biscuit",
     "weight": 40,
     "uom": "gm",
     "pack": 144,
     "length": 485,
     "width": 270,
     "height": 300,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000400,
     "name": "IFAD Lexus  Biscuit",
     "weight": 60,
     "uom": "gm",
     "pack": 144,
     "length": 500,
     "width": 300,
     "height": 370,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": "1400000116",
     "name": "IFAD Mixed Fruit Cream  Biscuit",
     "weight": 60,
     "uom": "gm",
     "pack": 144,
     "length": 410,
     "width": 380,
     "height": 420,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": "1400000115",
     "name": "IFAD Kazu Delight  Biscuit(ENG+EXP)",
     "weight": 70,
     "uom": "gm",
     "pack": 144,
     "length": 413,
     "width": 303,
     "height": 413,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000477,
     "name": "IFAD Kazu Delight Family  Biscuit",
     "weight": 220,
     "uom": "gm",
     "pack": 24,
     "length": 398,
     "width": 353,
     "height": 243,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000366,
     "name": "IFAD Choco Cream  Biscuit",
     "weight": 27,
     "uom": "gm",
     "pack": 48,
     "length": 295,
     "width": 130,
     "height": 150,
     "carton_weight": 1000
    },
    {
     "sap_code": "1400000130",
     "name": "IFAD Cheesy Bite  Biscuit",
     "weight": 40,
     "uom": "gm",
     "pack": 144,
     "length": 515,
     "width": 260,
     "height": 290,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": "1400000133",
     "name": "IFAD Cheesy Bite Family  Biscuit",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 508,
     "width": 288,
     "height": 193,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000181",
     "name": "IFAD Milk  Biscuit",
     "weight": 60,
     "uom": "gm",
     "pack": 144,
     "length": 498,
     "width": 283,
     "height": 353,
     "carton_weight": 1000,
     "remark": "6 Inner Carton"
    },
    {
     "sap_code": 1400000366,
     "name": "IFAD Choco Cream  Biscuit",
     "weight": 27,
     "uom": "gm",
     "pack": 48,
     "length": 295,
     "width": 130,
     "height": 150,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000122",
     "name": "IFAD Special Toast Biscuit (HAND CUT)",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 483,
     "width": 343,
     "height": 193,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000144",
     "name": "IFAD Special Toast Biscuit (HAND CUT)",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 483,
     "width": 343,
     "height": 373,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000132",
     "name": "IFAD Sweet Toast Biscuit (With Tray)",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 438,
     "width": 408,
     "height": 163,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000135",
     "name": "IFAD Sweet Toast Biscuit (With Tray)",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 568,
     "width": 428,
     "height": 203,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000330,
     "name": "IFAD Baby Toast Biscuit ",
     "weight": 150,
     "uom": "gm",
     "pack": 24,
     "length": 400,
     "width": 380,
     "height": 155,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000131",
     "name": "IFAD Baby Toast Biscuit (With Tray)",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 438,
     "width": 408,
     "height": 163,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000136",
     "name": "IFAD Baby Toast Biscuit (With Tray)",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 568,
     "width": 428,
     "height": 203,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000216",
     "name": "IFAD Muri Toast Biscuit",
     "weight": 40,
     "uom": "gm",
     "pack": 48,
     "length": 635,
     "width": 508,
     "height": 88.9,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000140",
     "name": "IFAD Muri Toast Biscuit",
     "weight": 150,
     "uom": "gm",
     "pack": 24,
     "length": 400,
     "width": 380,
     "height": 155,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000137",
     "name": "IFAD Muri Toast Biscuit (With Tray)",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 568,
     "width": 428,
     "height": 203,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000138",
     "name": "IFAD Muri Toast Biscuit(Jar)",
     "weight": 250,
     "uom": "gm",
     "pack": 24,
     "length": 378,
     "width": 278,
     "height": 298,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000139",
     "name": "IFAD Muri Toast Biscuit(Jar)",
     "weight": 600,
     "uom": "gm",
     "pack": 12,
     "length": 468,
     "width": 343,
     "height": 218,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000314,
     "name": "IFAD Stick Toast Biscuit",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 455,
     "width": 380,
     "height": 225,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000240",
     "name": "IFAD Dry Cake",
     "weight": 40,
     "uom": "gm",
     "pack": 48,
     "length": 303,
     "width": 248,
     "height": 118,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000387,
     "name": "IFAD Dry Cake(New- Red Color)",
     "weight": 100,
     "uom": "gm",
     "pack": 24,
     "length": 375,
     "width": 235,
     "height": 195,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000335,
     "name": "IFAD Dry Cake(Small BOX)",
     "weight": 110,
     "uom": "gm",
     "pack": 24,
     "length": 378,
     "width": 238,
     "height": 198,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000148",
     "name": "IFAD Dry Cake(Small BOX)",
     "weight": 130,
     "uom": "gm",
     "pack": 24,
     "length": 378,
     "width": 238,
     "height": 198,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000336,
     "name": "IFAD Dry Cake",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 518,
     "width": 263,
     "height": 193,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000188,
     "name": "IFAD Dry Cake (New- Red ATC)",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 340,
     "width": 240,
     "height": 225,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000239",
     "name": "IFAD Dry Cake",
     "weight": 300,
     "uom": "gm",
     "pack": 12,
     "length": 518,
     "width": 263,
     "height": 193,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000155",
     "name": "IFAD Dry Cake",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 525,
     "width": 375,
     "height": 255,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000367,
     "name": "IFAD Creamy Delight Lemon  Biscuit",
     "weight": 27,
     "uom": "gm",
     "pack": 48,
     "length": 295,
     "width": 130,
     "height": 150,
     "carton_weight": 1000
    },
    {
     "sap_code": 1400000368,
     "name": "IFAD Creamy Delight Pineapple  Biscuit",
     "weight": 27,
     "uom": "gm",
     "pack": 48,
     "length": 295,
     "width": 130,
     "height": 150,
     "carton_weight": 1000
    },
    {
     "sap_code": "1400000046",
     "name": "IFAD Egg (Stick) Noodles",
     "weight": 100,
     "uom": "gm",
     "pack": 48,
     "length": 393,
     "width": 218,
     "height": 113,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000047",
     "name": "IFAD Egg (Stick) Noodles",
     "weight": 180,
     "uom": "gm",
     "pack": 24,
     "length": 228,
     "width": 188,
     "height": 163,
     "carton_weight": 750,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000438,
     "name": "IFAD EGGY Instant Noodles (Super Chicken)",
     "weight": 240,
     "uom": "gm",
     "pack": 12,
     "length": 413,
     "width": 243,
     "height": 193,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000172",
     "name": "IFAD EGGY Instant Noodles (Super Masala)",
     "weight": 240,
     "uom": "gm",
     "pack": 24,
     "length": 558,
     "width": 248,
     "height": 288,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000165",
     "name": "IFAD EGGY Instant Noodles (Chicken)",
     "weight": 390,
     "uom": "gm",
     "pack": 24,
     "length": 493,
     "width": 433,
     "height": 278,
     "carton_weight": 583.333333333333,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000153",
     "name": "IFAD EGGY Instant Noodles (Super Masala)",
     "weight": 390,
     "uom": "gm",
     "pack": 24,
     "length": 493,
     "width": 433,
     "height": 278,
     "carton_weight": 583.333333333333,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000407 ",
     "name": "IFAD Wavy Chips (Bar-B-Q)  CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 603,
     "width": 293,
     "height": 448,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": "1400000449 ",
     "name": "IFAD Wavy Chips (Chicken) CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 603,
     "width": 293,
     "height": 448,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000402,
     "name": "IFAD Eggy Pillow Chips (Bar-B-Q) CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000403,
     "name": "IFAD Eggy Pillow Chips (Masala) CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000404,
     "name": "IFAD Eggy  Stix Chips (Bar-B-Q) CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000405,
     "name": "IFAD Eggy  Stix Chips (MASALA) CTN",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000406,
     "name": "IFAD  Stix Chips (TOMATO KETCHUP)",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000374,
     "name": "IFAD Chips (Potato Crackers)",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000232,
     "name": "IFAD  EGGY Rolls (Sriracha)",
     "weight": 20,
     "uom": "gm",
     "pack": 80,
     "length": 583,
     "width": 263,
     "height": 433,
     "carton_weight": 100,
     "remark": "4 Inner Poly"
    },
    {
     "sap_code": 1400000334,
     "name": "IFAD Mustard Oil",
     "weight": 200,
     "uom": "ml",
     "pack": 24,
     "length": 300,
     "width": 205,
     "height": 178,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000337,
     "name": "IFAD Mustard Oil",
     "weight": 200,
     "uom": "ml",
     "pack": 20,
     "length": 250,
     "width": 198,
     "height": 180,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000120",
     "name": "IFAD Mustard Oil",
     "weight": 250,
     "uom": "ml",
     "pack": 24,
     "length": 328,
     "width": 213,
     "height": 183,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000333,
     "name": "IFAD Mustard Oil",
     "weight": 400,
     "uom": "ml",
     "pack": 24,
     "length": 380,
     "width": 245,
     "height": 212,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000338,
     "name": "IFAD Mustard Oil",
     "weight": 400,
     "uom": "ml",
     "pack": 20,
     "length": 320,
     "width": 245,
     "height": 212,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000121",
     "name": "IFAD Mustard Oil",
     "weight": 500,
     "uom": "ml",
     "pack": 24,
     "length": 403,
     "width": 268,
     "height": 228,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000157",
     "name": "IFAD Mustard Oil",
     "weight": 1000,
     "uom": "ml",
     "pack": 12,
     "length": 333,
     "width": 227,
     "height": 273,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000151,
     "name": "IFAD Chinigura Aromatic Rice",
     "weight": 1000,
     "uom": "gm",
     "pack": 10,
     "length": 313,
     "width": 225,
     "height": 195,
     "carton_weight": 1000,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000339,
     "name": "IFAD Chanachur",
     "weight": 18,
     "uom": "gm",
     "pack": 192,
     "length": 840,
     "width": 480,
     "height": 190,
     "carton_weight": 500,
     "remark": "4 inner poly"
    },
    {
     "sap_code": "1400000257",
     "name": "IFAD Chanachur",
     "weight": 70,
     "uom": "gm",
     "pack": 96,
     "length": 373,
     "width": 358,
     "height": 383,
     "carton_weight": 500,
     "remark": "4 inner poly"
    },
    {
     "sap_code": 1400000124,
     "name": "IFAD Chanachur Normal",
     "weight": 150,
     "uom": "gm",
     "pack": 48,
     "length": 443,
     "width": 348,
     "height": 228,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000365,
     "name": "IFAD Chanachur Hot",
     "weight": 150,
     "uom": "gm",
     "pack": 48,
     "length": 433,
     "width": 363,
     "height": 228,
     "carton_weight": 500,
     "remark": "2 Inner Poly"
    },
    {
     "sap_code": "1400000228",
     "name": "IFAD Chanachur  (NEW CTN SIZE)",
     "weight": 300,
     "uom": "gm",
     "pack": 24,
     "length": 423,
     "width": 318,
     "height": 253,
     "carton_weight": 500,
     "remark": "2 Inner Poly"
    },
    {
     "sap_code": 1400000584,
     "name": "IFAD Chanachur",
     "weight": 50,
     "uom": "gm",
     "pack": 96,
     "length": 373,
     "width": 358,
     "height": 383,
     "carton_weight": 500,
     "remark": "4 inner poly"
    },
    {
     "sap_code": 1400000585,
     "name": "IFAD Chanachur Normal",
     "weight": 130,
     "uom": "gm",
     "pack": 48,
     "length": 443,
     "width": 348,
     "height": 228,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000586,
     "name": "IFAD Chanachur  (NEW CTN SIZE)",
     "weight": 275,
     "uom": "gm",
     "pack": 24,
     "length": 423,
     "width": 318,
     "height": 253,
     "carton_weight": 500,
     "remark": "2 Inner Poly"
    },
    {
     "sap_code": "1400000078",
     "name": "IFAD Atta (Local)",
     "weight": 1000,
     "uom": "gm",
     "pack": 24,
     "length": 600,
     "width": 480,
     "height": 160,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000183",
     "name": "IFAD Atta CTN",
     "weight": 2000,
     "uom": "gm",
     "pack": 10,
     "length": 453,
     "width": 328,
     "height": 228,
     "carton_weight": 500,
     "remark": "CTN"
    },
    {
     "sap_code": "1400000077",
     "name": "IFAD Brown Atta (Local)",
     "weight": 1000,
     "uom": "gm",
     "pack": 24,
     "length": 600,
     "width": 480,
     "height": 160,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000281",
     "name": "IFAD Maida",
     "weight": 2000,
     "uom": "gm",
     "pack": 10,
     "length": 453,
     "width": 328,
     "height": 228,
     "carton_weight": 500,
     "remark": "CTN"
    },
    {
     "sap_code": "1400000187",
     "name": "IFAD Salt CTN",
     "weight": 500,
     "uom": "gm",
     "pack": 24,
     "length": 345,
     "width": 265,
     "height": 155,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000186",
     "name": "IFAD Salt CTN",
     "weight": 1000,
     "uom": "gm",
     "pack": 12,
     "length": 345,
     "width": 265,
     "height": 155,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000152",
     "name": "IFAD Puffed Rice",
     "weight": 200,
     "uom": "gm",
     "pack": 20,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000152",
     "name": "IFAD Puffed Rice",
     "weight": 200,
     "uom": "gm",
     "pack": 40,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000170",
     "name": "IFAD Puffed Rice",
     "weight": 400,
     "uom": "gm",
     "pack": 20,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000353,
     "name": "IFAD Puffed Rice",
     "weight": 400,
     "uom": "gm",
     "pack": 10,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000348,
     "name": "IFAD Puffed Rice",
     "weight": 250,
     "uom": "gm",
     "pack": 20,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000352,
     "name": "IFAD Puffed Rice",
     "weight": 500,
     "uom": "gm",
     "pack": 10,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000162",
     "name": "IFAD Puffed Rice",
     "weight": 250,
     "uom": "gm",
     "pack": 32,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000126",
     "name": "IFAD Puffed Rice",
     "weight": 500,
     "uom": "gm",
     "pack": 16,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000126",
     "name": "IFAD Flattened Rice",
     "weight": 500,
     "uom": "gm",
     "pack": 12,
     "length": 330,
     "width": 225,
     "height": 195,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000448 ",
     "name": "IFAD Jhal Muri- Wasabi",
     "weight": 50,
     "uom": "gm",
     "pack": 64,
     "length": 500,
     "width": 275,
     "height": 430,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000587,
     "name": "IFAD Jhal Muri- Wasabi",
     "weight": 40,
     "uom": "gm",
     "pack": 64,
     "length": 500,
     "width": 275,
     "height": 430,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "-",
     "name": "IFAD Jhal Muri- Spicy",
     "weight": 150,
     "uom": "gm",
     "pack": 24,
     "length": 500,
     "width": 355,
     "height": 275,
     "carton_weight": 100,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000389,
     "name": "IFAD CHILLI POWDER (JAR)",
     "weight": 250,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000412,
     "name": "IFAD CHILLI POWDER (JAR)",
     "weight": 200,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000390,
     "name": "IFAD TURMERIC POWDER (JAR)",
     "weight": 250,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000413,
     "name": "IFAD TURMERIC POWDER (JAR)",
     "weight": 200,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000391,
     "name": "IFAD CUMIN SEED POWDER (JAR)",
     "weight": 250,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000414,
     "name": "IFAD CUMIN SEED POWDER (JAR)",
     "weight": 200,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000415,
     "name": "IFAD CORIANDER POWDER (JAR)",
     "weight": 200,
     "uom": "gm",
     "pack": 24,
     "length": 415,
     "width": 280,
     "height": 165,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000446,
     "name": "IFAD WHOLE SPICES- DRY CHILLI",
     "weight": 1000,
     "uom": "gm",
     "pack": 10,
     "length": 680,
     "width": 390,
     "height": 310,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": 1400000446,
     "name": "IFAD WHOLE SPICES- DRY CHILLI",
     "weight": 50,
     "uom": "gm",
     "pack": 60,
     "length": 460,
     "width": 360,
     "height": 240,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": " 1400000447",
     "name": "IFAD WHOLE SPICES- BAY LEAF",
     "weight": 50,
     "uom": "gm",
     "pack": 60,
     "length": 460,
     "width": 360,
     "height": 240,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    },
    {
     "sap_code": "1400000159",
     "name": "IFAD LACHCHA SEMAI",
     "weight": 150,
     "uom": "gm",
     "pack": 40,
     "length": 418,
     "width": 393,
     "height": 308,
     "carton_weight": 500,
     "remark": "No Inner Carton"
    }
   ]

   

                                    


function ProductList(props) {
    const tableTitle = 'Customers';

    const [product, setProduct] = useState();

    props.setNavParam('productList')

    props.setProductCount(products.length)

    //modal toggle state
    const [isOpen, open] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    useEffect(() => {
        setModalTitle('Product Detail');
    }, [isOpen])
    

    function toggleModal() {
        open(false)
    }

    const viewProductDetail = (product) => {
        console.log(product.name)
        setProduct(product)
        open(true)
    }


    return (
        <>
            <div class="row">
                <div class="col-lg-12">
                    <section class="card">
                        <header class="card-header">
                            <div class="card-actions">
                                {/* <a href="#" class="card-action card-action-toggle" data-card-toggle></a>
                                <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a> */}
                            </div>
                            <b>Product List from SAP</b>
            
                            {/* <Link className='btn btn-primary' to={'/product'}>+ Add Product</Link> */}
                        </header>
                        <div class="card-body">
                            <ProductTable products={products} viewProductDetail={viewProductDetail}/>
                        </div>
                    </section>
                </div>
                <CustomModal 
                    toggleModal={toggleModal} 
                    openStatus={isOpen}
                    title={modalTitle}
                    modalForm={AddProductForm}
                    data={product}
                />
            </div>
        </>
    ) 
}

export default ProductList;