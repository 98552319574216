import $ from 'jquery'
import { appendScript } from "../utility/AppendScript";
import { Link } from 'react-router-dom';

const navActive = {
	color: "#0088CC"
}

const navRegular = {
	color: "#abb4be"
}




function CustomSidebar(props) {
	appendScript('js/theme.js');

    return (
        <>
			<aside id="sidebar-left" className="sidebar-left">
				    <div className="sidebar-header">
				        <div className="sidebar-title">
				            Navigation
				        </div>
				        <div className="sidebar-toggle d-none d-md-block" data-toggle-class="sidebar-left-collapsed" data-target="html" data-fire-event="sidebar-left-toggle">
				            <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
				        </div>
				    </div>
				
				    <div className="nano">
				        <div className="nano-content">
				            <nav id="menu" className="nav-main" role="navigation">
				            
				                <ul className="nav nav-main">
									{
										(localStorage.getItem('role') !== 'accounts') &&
										<li className={ (props.navParam === 'addOrder') ? 'nav-active': ''}>
											<Link className="nav-link" to={'/order'} style={(props.navParam === 'addOrder') ? navActive : navRegular} >
												<i className="fa fa-receipt"></i>
												<span>Place Order</span>
											</Link>                        
										</li>
									}
									{
										(localStorage.getItem('role') !== 'accounts') &&
										<li className={ (props.navParam === 'orderList') && 'nav-active'}>
											<Link className="nav-link" to={'/orders'} style={(props.navParam === 'orderList') ? navActive : navRegular} >
												<span className="float-right badge badge-primary">{props.orderCount}</span>
												<i className="fa fa-receipt"></i>
												<span>Orders</span>
											</Link>                        
										</li>
									}
									{
										(localStorage.getItem('role') !== 'customer' && localStorage.getItem('role') !== 'accounts') &&
										<li className={ (props.navParam === 'salescontract') && 'nav-active'}>
											<Link className="nav-link" to={'/salescontract'} style={(props.navParam === 'salescontract') ? navActive : navRegular} >
												<span className="float-right badge badge-primary">{props.orderCount}</span>
												<i className="fa fa-receipt"></i>
												<span>Sales Contract</span>
											</Link>                        
										</li>
									}
									

									{
										(localStorage.getItem('role') !== 'customer') &&
										<li class={(props.navParam === 'approval' || props.navParam === 'approvalAccounts') ? 'nav-parent ' + 'nav-expanded': 'nav-parent'}>
											<a className="nav-link" href="#">
												<i className="fas fa-cog" aria-hidden="true"></i>
												<span>Approvals</span>
											</a>
											<ul className="nav nav-children">
												<li className={ (props.navParam === 'approvalAccounts') && 'nav-active'}>
													<Link className="nav-link" to={'/accounts'}>
														<span>Accounts</span>
													</Link>                        
												</li>
											</ul>
										</li>
									}

									{
										(localStorage.getItem('role') !== 'customer' && localStorage.getItem('role') !== 'accounts') &&
										<li class={(props.navParam === 'productList' || props.navParam === 'customerList' || props.navParam === 'users' || props.navParam === 'user') ? 'nav-parent ' + 'nav-expanded': 'nav-parent'}>
											<a className="nav-link" href="#">
												<i className="fas fa-cog" aria-hidden="true"></i>
												<span>Configuration</span>
											</a>
											<ul className="nav nav-children">
												<li className={ (props.navParam === 'productList') && 'nav-active'}>
													<Link className="nav-link" to={'/products'}>
														<span className="float-right badge badge-primary">{props.productCount}</span>
														{/* <i className="fas fa-envelope" aria-hidden="true"></i> */}
														<span>Products</span>
													</Link>                        
												</li>
												<li className={ (props.navParam === 'customerList') && 'nav-active'}>
													<Link className="nav-link" to={'/customers'}>
													{/* <i className="fas fa-users" aria-hidden="true"></i> */}
														Customers
													</Link>
												</li>
												<li className={ (props.navParam === 'users' || props.navParam === 'user') && 'nav-active'}>
													<Link className="nav-link" to={'/users'}>
													{/* <i className="fas fa-users" aria-hidden="true"></i> */}
														Users
													</Link>
												</li>
											</ul>
										</li>
									}
				                </ul>
				            </nav>
				        </div>
				    </div>
				
				</aside>
        </>
    )
}

export default CustomSidebar;