import { useState, useEffect } from "react"
import axios from "axios"
import SalesContractMailBody from "../templates/SalesContractMailBody"
import ReactDOMServer from 'react-dom/server';


const inlineSearch = {
    "position": "absolute",
    "zIndex": "2",
    "display": "block",
    "width": "1.375rem",
    "height": "1.375rem",
    "lineHeight": "1.375rem",
    "textAlign": "center",
    "pointerEvents": "none",
    "color": "#aaa",
    "right": "0"
}

const paddingLeft = {
    "paddingRight": "1.375rem"
}

const relativePosition = {
    "position" : "relative"
}




function PrintContractTable (props) {

    const [mailbody, setMailBody] = useState("")

    useEffect(() => {
        console.log(mailbody)
    }, [])
    function sendEmail (order) {
        const response = axios.post(localStorage.getItem('baseApiUrl')+'ImplExpSendSalesContract', order, {
                headers: {
                    Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                },
            }
        )
        .then(response => {
          alert(response.data.Data)
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }

    
    
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order No</th>
                        <th>Order Date</th>
                        <th>Customer</th>
                        <th>City</th>
                        <th>Dst. Port</th>
                        <th>Container</th>
                        <th>CBM</th>
                        <th className="text-center">Send Email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.orders.map((order, index) => {
                            const handleOrderDetail = () => {
                                const orderToSent = {}
                                orderToSent.ORDER = order
                                const mailBody = <SalesContractMailBody order={order} />
                                
                                orderToSent.MAIL_BODY = ReactDOMServer.renderToString(mailBody)
                                setMailBody(<SalesContractMailBody order={orderToSent} />)
                                // orderToSent.MAIL_BODY = mailbody
                                console.log("----------------mailbody----")
                                console.log(orderToSent)
                                sendEmail(orderToSent)
                            }

                            return (
                                <>
                                    <tr key={index + order.ORDER_ID}>
                                        <td>{index + 1}</td>
                                        <td>{order.ORDER_ID.toString().padStart(6, '0')}</td>
                                        <td>{order.ORDER_DATE}</td>
                                        <td>{order.CUSTOMER_NAME}</td>
                                        <td>{order.CITY}</td>
                                        <td>{order.DESTINATION_PORT}</td>
                                        <td>{order.CONTAINER_SIZE}</td>
                                        <td>{order.CBM}</td>
                                        <td className="text-center"><a className="text-primary text-center font-weight-bold" href="#" onClick={handleOrderDetail}><i class="fa fa-paper-plane" aria-hidden="true"></i></a></td>
                                    </tr>
                                </>
                              )
                        })
                    }
                </tbody>
            </table>
        </>
    ) 
}

export default PrintContractTable;