import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { FirstCap } from "../utility/FirstCap";

function PageHeader(props) {
    const location = useLocation();
    console.log(location.pathname);
    const [pageHeader, setPageHeader] = useState('Home')


    useEffect(() => {
        var initPageHeader = FirstCap(location.pathname.replace('/', ''))
        initPageHeader = initPageHeader === 'Salescontract' ? 'Sales Contract' : initPageHeader
        setPageHeader(initPageHeader)
    }, [location])

    
    return (
        <>
            <header className="page-header">
                <h2>{pageHeader}</h2>

                <div className="right-wrapper text-right">
                    {/* <ol className="breadcrumbs">
                        <li>
                            <a href="index.html">
                                <i className="fas fa-home"></i>
                            </a>
                        </li>
                        <li><span>UI Elements</span></li>
                        <li><span>Modals</span></li>
                    </ol>

                    <a className="sidebar-right-toggle" data-open="sidebar-right"><i className="fas fa-chevron-left"></i></a> */}
                </div>
            </header>
        </>
    )
}

export default PageHeader;

