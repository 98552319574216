import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import axios from "axios";
import UserTable from "../../tables/UserTable";

const UserList = (props) => {
    const [users, setUsers] = useState([{}])
    props.setNavParam('users')

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpUser?user_id=', {
                headers: {
                  Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                },
              });
              console.log(response.data.Data)
            setUsers(response.data.Data)
            } catch (error) {
              console.log('Error:', error)
            } 
          };
      
          fetchData();
         
    }, [])
    return (
        <>
            <div class="row">
                <div class="col-lg-12">
                    <section class="card">
                        <header class="card-header">
                            <div class="card-actions">
                                {/* <a href="#" class="card-action card-action-toggle" data-card-toggle></a>
                                <a href="#" class="card-action card-action-dismiss" data-card-dismiss></a> */}
                            </div>
            
                            <Link className='btn btn-primary' to='/user'>+ Add User</Link>
                        </header>
                        <div class="card-body">
                            <UserTable users={users} />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default UserList