import $ from 'jquery'
import { useState, useEffect, useRef } from "react";
import axios from "axios";

const User = (props) => {


    const [selectOptions, setSelectOptions] = useState([{"label":"select customer", "value": ""}])
    const [customers, setCustomers] = useState([{}])
    const [customer, setCustomer] = useState({})
    const [customerCode, setCustomerCode] = useState({})
    const [user, setUser] = useState({"CustomerCode": '',})
    const [userId, setUserId] = useState('')
    const [emailId, setEmailId] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        props.setNavParam('user')
        
        const fetchData = async () => {
          try {
            const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpCustomers?sap_code=', {
              headers: {
                Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
              },
            });
            setCustomers(response.data.Data)
            const options = response.data.Data.map((customer) => ({
                label: customer.CUSTOMER_NAME, 
                value: customer.SAP_CODE,
            }))
            setSelectOptions([...selectOptions, ...options]);
          } catch (error) {
            console.log('Error:', error)
          } 
        };
    
        fetchData();
    }, []);

    
    function handleRoleChange (e) {
        setRole(e.target.value)
        setUser({
            ...user,
            Role: e.target.value,
        })
    }

    function handleNameChange (e) {
        setName(e.target.value)
        setUser({
            ...user,
            Name: e.target.value,
        })        
    }

    function handlePasswordChange (e) {
        setPassword(e.target.value)
        setUser({
            ...user,
            Password: e.target.value,
        })
        console.log(user)     
    }

    function handleUserIdChange (e) {
        setUserId(e.target.value)
        setUser({
            ...user,
            UserId: e.target.value,
        })        
    }

    function handleEmailIdChange (e) {
        setEmailId(e.target.value)
        setUser({
            ...user,
            Email: e.target.value,
        })        
    }

    function handleCustomerChange(e) {
        const selectedCustomer = customers.find((item) => item.SAP_CODE === e.target.value);
        setCustomer(selectedCustomer)
        setCustomerCode(e.target.value)
        setUser({
            ...user,
            CustomerCode: e.target.value,
        })
    }


    function handleSubmit (e) {
        e.preventDefault()
        console.log(user)
        const response = axios.post(localStorage.getItem('baseApiUrl')+'ImplExpUser', user, {
                headers: {
                    Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                },
            }
        )
        .then(response => {
          setCustomer({})
          setUser({})
          setName('')
          setUserId('')
          setPassword('')
          setRole('')
          setEmailId('')
        //   alert(response.data.Data)
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }

    const selectRef = useRef()

    useEffect(() => {
        // Initialize the select2 plugin on component mount
        $(selectRef.current).select2();
        $(selectRef.current).on('change', handleCustomerChange);

        
        // Clean up the select2 instance on component unmount
        return () => {
          $(selectRef.current).select2('destroy');
          $(selectRef.current).off('change', handleCustomerChange);
        };
    }, [customers]);


    return (
        <>
            <div className="row">
                <div className="col">
                    <section className="card">
                        <header className="card-header">
                            <div className="card-actions">
                                <a href="#" className="card-action card-action-toggle" data-card-toggle></a>
                                <a href="#" className="card-action card-action-dismiss" data-card-dismiss></a>
                            </div>
            
                            <h2 className="card-title">Create User</h2>
                        </header>
                        <div className="card-body">
                            <form className="form-horizontal form-bordered" method="post" onSubmit={handleSubmit}>
                                <div class="form-group row">
                                    <label class="col-lg-3 control-label text-lg-right pt-2">Select Role</label>
                                    <div class="col-lg-6">
                                        <select class="form-control form-control-sm mb-3" onChange={handleRoleChange}>
                                            <option value="">Select Role</option>
                                            <option value="customer">Customer</option>
                                            <option value="administrator">Administrator</option>
                                            <option value="accounts">Accounts</option>
                                        </select>
                                    </div>
                                </div>
                                {(role==='customer') && 
                                    <div className="form-group row">
                                        <label className="col-lg-3 control-label text-lg-right pt-2" for="inputDefault">Customer</label>
                                        <div className="col-lg-6">
                                        {/* <SelectTag selectOptions={selectOptions} onChange={handleCustomerChange}/> */}
                                            <select ref={selectRef} className="form-control form-contol-sm" onChange={handleCustomerChange} value={customer.SAP_CODE} required>
                                                {
                                                    selectOptions.map((option, index) => {
                                                        return (
                                                            <option value={option.value} key={index}>{option.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                }

                                

                                <div className="form-group row">
                                    <label className="col-lg-3 control-label text-lg-right pt-2" for="inputDefault">Name</label>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm" id="inputDefault" onChange={handleNameChange} value={name}/>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-lg-3 control-label text-lg-right pt-2" for="inputDefault">User Id</label>
                                    <div className="col-lg-6">
                                        <input type="text" className="form-control form-control-sm" id="inputDefault" onChange={handleUserIdChange} value={userId}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 control-label text-lg-right pt-2" for="inputDefault">Email Id</label>
                                    <div className="col-lg-6">
                                        <input type="email" className="form-control form-control-sm" id="inputDefault" onChange={handleEmailIdChange} value={emailId}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 control-label text-lg-right pt-2" for="inputDefault">Password</label>
                                    <div className="col-lg-6">
                                        <input type="password" className="form-control form-control-sm" id="inputDefault" onChange={handlePasswordChange} value={password}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 text-right">
                                        <button className='btn btn-success' type='submit'>Save</button>
                                    </div>
                                </div>

                                
                                
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default User