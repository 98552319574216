import SelectTag from "../components/SelectTag";
import Input from "../components/Input";
import { appendScript } from "../utility/AppendScript";

const categories = [
    { value: 'biscuit', label: 'Biscuit' },
    { value: 'bakery-biscuit', label: 'Bakery-biscuit' },
    { value: 'noodles', label: 'Noodles' },
    { value: 'chips', label: 'Chips' },
    { value: 'cake', label: 'Cake' },
    { value: 'others', label: 'Others' },   
    ]


function AddProductForm (props) {

    appendScript('js/theme.js');
    appendScript('js/theme.init.js');
    
    const handleOnChange = (e) => {
        // setInputs({ eventTitle : e.target.elements.event_title.value })
        console.log(e.target.value)
    }
    
    const handleOnSubmit = (e) => {
        // e.preventDefault()
        console.log(e.target.elements.eventTitle.value)
    }

    // <td nowrap>{product.sap_code}</td>
    // <td nowrap>{product.name}</td>
    // <td nowrap>{product.weight}</td>
    // <td nowrap>{product.uom}</td>
    // <td nowrap>{product.pack}</td>
    // <td nowrap>{product.length + "X" + product.width + "X" + product.height}</td>
    // <td nowrap>{product.carton_weight}</td>
    // <td nowrap>{product.remark}</td>

    
    return (
        <>
            <form className="form-horizontal form-bordered" method="get">
                <div className="row form-group">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Item Code</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.sap_code}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Item Name</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.name}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Weight</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.weight + " " + props.data.uom}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Dimension</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.length + "X" + props.data.width + "X" + props.data.height}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Pcs/Carton</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.pack}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="col-form-label" for="formGroupExampleInput">Weight</label>
                            <Input 
                                type={"text"}
                                class={"form-control-sm"}
                                name={"name"}
                                value={props.data.carton_weight}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12">
                        <label className="col-form-label" for="formGroupExampleInput">Remarks</label>
                        <textarea className="form-control" rows="5" placeholder="Provide Remarks Here"></textarea>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12 text-lg-right">
                        <input className="btn btn-default" type="button" onClick={props.closeModal} value={'Close'}/>
                        <input className="btn btn-primary ml-2" type="submit" value={'Save'}/>
                    </div>
                </div>
                
            </form>
        </>
    )
}


export default AddProductForm;