import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios";


function SignIn (props) {
    const navigate = useNavigate();
    console.log(localStorage.getItem("sessAuth"))
    
    useEffect(() => {
        if(localStorage.getItem("sessAuth")) {
            navigate('/order')
        }
    },[])


    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')

    const [credential, setCredential] = useState({})

    useEffect(() => {
        setCredential({
            userId: userId,
            password: password,
        })
    }, [userId, password])

    function handleUserIdChange (e){
        setUserId(e.target.value)
    }

    function handlePasswordChange (e){
        setPassword(e.target.value)
    }

    const login = () => {
        const fetchData = async () => {
            try {
            const response = await axios.post('https://bi.ifadgroup.com/public/Api/ImplExpLogin', credential , {
                headers: {
                Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
                },
            });
                console.log(response.data.Data)
                if(response.data.Data.length > 0) {
                    console.log("found")
                    props.authenticate(true)
                    localStorage.setItem('sessAuth', response.data.Data[0].USER_ID)
                    localStorage.setItem('role', response.data.Data[0].ROLE)
                    localStorage.setItem('customerCode', response.data.Data[0].CUSTOMER_CODE)
                    localStorage.setItem('baseApiUrl', 'https://bi.ifadgroup.com/public/Api/')
                    navigate('/order')
                } else {
                    console.log("Not Found")
                }
            } catch (error) {
            console.log('Error:', error)
            } 
        };
    
        fetchData();
    }

    function handleSubmit(e) {
        e.preventDefault()
        login()
    }

    return (
        <>
            <section className="body-sign">
                <div className="center-sign">
                    <a href="/" className="logo float-left">
                        <img src="img/logo.png" height="54" alt="Porto Admin" />
                    </a>

                    <div className="panel card-sign">
                        <div className="card-title-sign mt-3 text-right">
                            <h2 className="title text-uppercase font-weight-bold m-0"><i className="fas fa-user mr-1"></i> Sign In</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label>Username</label>
                                    <div className="input-group">
                                        <input 
                                            name="username" type="text" className="form-control form-control-lg"
                                            value={userId} onChange={handleUserIdChange}
                                        />
                                        <span className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <div className="clearfix">
                                        <label className="float-left">Password</label>
                                        <a href="pages-recover-password.html" className="float-right">Lost Password?</a>
                                    </div>
                                    <div className="input-group">
                                        <input 
                                            name="pwd" type="password" className="form-control form-control-lg"
                                            value={password} onChange={handlePasswordChange}
                                        />
                                        <span className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="checkbox-custom checkbox-default">
                                            <input id="RememberMe" name="rememberme" type="checkbox"/>
                                            <label>Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 text-right">
                                        <button className="btn btn-primary mt-2" type="submit">Sign In</button>
                                    </div>
                                </div>

                                {/* <p className="text-center">Don't have an account yet? <a href="pages-signup.html">Sign Up!</a></p> */}

                            </form>
                        </div>
                    </div>

                    <p className="text-center text-muted mt-3 mb-3">&copy; Copyright 2023. Developed by Ifad Group IT.</p>
                </div>
            </section>
        </>    
    )
}

export default SignIn;