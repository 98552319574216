import { useState, useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import PendingApprovalAccountsTable from "../tables/PendingApprovalAccountsTable"
import PastApprovalAccountsTable from "../tables/PastApprovalAccountsTable";
import axios from "axios";


function ApprovalAccounts(props) {
  const [orders, setOrders] = useState([])
  const [ordersPending, setOrdersPending] = useState([])
  const [ordersPast, setOrdersPast] = useState([])



  const navigate = useNavigate()  
  useEffect(() => {
    if(!localStorage.getItem('sessAuth')) {
      navigate('/sign-in')
    }
    props.setNavParam('approvalAccounts')
  },[])

  useEffect(() => {
    console.log(orders)
  }, [orders])

  useEffect(() => {
    if(orders.length > 0) {
      const filteredDataPending = orders.filter(order => order.STATUS === 1)
      setOrdersPending(filteredDataPending)

      const filteredDataPast = orders.filter(order => order.STATUS === 9)
      setOrdersPast(filteredDataPast)
    }
  }, [orders])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(localStorage.getItem('baseApiUrl')+'ImplExpOrder?order_id=', {
          headers: {
            Authorization: 'Basic ' + btoa('hrt:hrt'), // Replace with username and password
          },
        });
        console.log(response.data.Data)
        setOrders(response.data.Data)
      } catch (error) {
        console.log('Error:', error)
      } 
    };
    fetchData();
  }, []);

  const removeOrder = (orderIdToRemove) => {
    const updatedOrders = ordersPending.filter((order) => order.ORDER_ID !== orderIdToRemove);
    setOrdersPending(updatedOrders);
  };


  return (
      <>
      <div className="row">
          <div className="col">
              <div class="inner-toolbar clearfix">
                  <ul>
                      <li class="right">
                          <ul class="nav nav-pills nav-pills-primary">
                              <li class="nav-item">
                                  <label>Type</label>
                              </li>
                              <li class="nav-item">
                                  <a class="nav-link active" href="#pending-orders" data-toggle="tab">Pending Orders</a>
                              </li>
                              <li class="nav-item">
                                  <a class="nav-link" href="#approved-orders" data-toggle="tab">Approved Orders</a>
                              </li>
                          </ul>
                      </li>
                  </ul>
              </div>

              <section class="card">
                  <div class="card-body tab-content">
                      <div id="pending-orders" class="tab-pane active">
                          <PendingApprovalAccountsTable orders={ordersPending} removeOrder={removeOrder}/>
                      </div>
                      <div id="approved-orders" class="tab-pane">
                          <PastApprovalAccountsTable orders={ordersPast}/>
                      </div>
                  </div>
              </section>
          </div>
            
                  
        </div>
          
      </>
  )
}


export default ApprovalAccounts;