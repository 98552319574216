function Input(props) {
    return (
        <>
            <input 
                type={props.type} class={"form-control "+props.class}
                id="inputDefault" name={props.name} 
                placeholder={props.placeholder}
                required={props.required}
                value={props.value}
                onChange={props.onChange}
                min={props.min}
                onFocus={props.onFocus}
                disabled={props.isDisabled}
                readOnly={props.isReadOnly}
            />
        </>
    )
}

export default Input;