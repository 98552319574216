function CustomerTable (props) {
    return(
        <>
            <table className="table-responsive-sm table-bordered table-striped table-valign-middle m-1" style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Customer ID</th>
                        <th>Company</th>
                        <th>Contact Person</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Phone</th>
                        <th>Post Code</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.customers.map((customer, index) => {
                            return(
                                <tr key={index}>
                                    <td>{index + 1 }</td>
                                    <td>{customer.SAP_CODE}</td>
                                    <td>{customer.CUSTOMER_NAME}</td>
                                    <td>{customer.CONTACT_PERSON}</td>
                                    <td>{customer.STREET}</td>
                                    <td>{customer.CITY}</td>
                                    <td>{customer.PHONE}</td>
                                    <td>{customer.POSTAL_CODE}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            
        </>
    ) 
}

export default CustomerTable;