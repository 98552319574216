import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import CustomSidebar from './components/CustomSidebar';
import PageHeader from './components/PageHeader';
import SignIn from './Pages/Login/SignIn';
import CustomerList from './Pages/CustomerList';
import ProductList from './Pages/ProductList';
import AddProduct from './Pages/AddProduct';
import AddOrder from './Pages/AddOrder';
import OrderList from './Pages/OrderList';
import Home from './Pages/Home';


import './App.css';
import ApprovalAccounts from './Pages/ApprovalAccounts';
import 'select2/dist/css/select2.min.css';
import SalesContract from './Pages/SalesContract';
import UserList from './Pages/User/UserList';
import User from './Pages/User/Forms/User';

function App() {

  const [isAuthenticated, authenticate] = useState(true)
  const [user, setUser] = useState({})
  const [navParam, setNavParam] = useState('/')
  const [customerCount, setCustomerCount] = useState(0)
  const [productCount, setProductCount] = useState(0)
  const [orderCount, setOrderCount] = useState(0)
 

  console.log(localStorage.getItem('sessAuth'))
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/sign-in' element={<SignIn authenticate={authenticate} 
          setUser={setUser} isAuthenticated={isAuthenticated} user={user} setNavParam={setNavParam}/>} 
        
        />
      </Routes>
        <>
          {isAuthenticated && <Header />}
          <div className="inner-wrapper">
            <CustomSidebar 
              navParam={navParam} 
              productCount={productCount}
              orderCount={orderCount}
              customerCount={customerCount}
            />
            <section role="main" className="content-body">
                <PageHeader />
                <Routes>
                  {/* <Route path='/' element={<Orders />}/> */}
                  <Route path='/' element={<Home setNavParam={setNavParam} />} />
                  <Route 
                    path='/order' 
                    element={<AddOrder
                               setNavParam={setNavParam}
                              />
                            } 
                  />
                  <Route path='/users' element={<UserList setNavParam={setNavParam} />} />
                  <Route path='/user' element={<User setNavParam={setNavParam} />} />
                  <Route path='/orders' element={<OrderList setNavParam={setNavParam} />} />
                  <Route path='/accounts' element={<ApprovalAccounts setNavParam={setNavParam}/>} />
                  <Route path='/product' element={<AddProduct setNavParam={setNavParam} setProductCount={setProductCount}/>} />
                  <Route path='/products' element={<ProductList setNavParam={setNavParam} setProductCount={setProductCount}/>} />
                  <Route path='/customers' element={<CustomerList setNavParam={setNavParam} />} />
                  <Route path='/salescontract' element={<SalesContract setNavParam={setNavParam}/>} />
                </Routes>
              
            </section>
          </div>
        </>
      </BrowserRouter>
  );
}

export default App;
