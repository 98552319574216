import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function Home (props) {

    const navigate = useNavigate()  
    useEffect(() => {
        if(!localStorage.getItem('sessAuth')) {
        navigate('/sign-in')
        }
    },[])

    return (
        <>
            <h1>Welcome to order management system</h1>
        </>
    )
}



export default Home